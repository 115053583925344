document.querySelectorAll('.preview section, .menu-link').forEach(section=> 
    section.addEventListener("click", function(){clickPreview(section)}));

function clickPreview(section) {
    document.querySelectorAll(".active").forEach(e=>e.classList.remove("active"));
    document.querySelector("#"+section.attributes["data-content"].value).classList.add("active");
    setTimeout(function(){
        document.querySelector(".active").style.overflow = "auto";
        const closeBtn = document.querySelector('.active .close');
        closeBtn.addEventListener("click", function(){clickClose(closeBtn)});
    },"1300")
}

function clickClose(closeBtn){
    closeBtn.removeEventListener("click", function(){clickClose(closeBtn)});
    const active = document.querySelector(".active");
    active.removeAttribute("style");
    active.style.minHeight = "0";
    setTimeout(function(){
        active.removeAttribute("style");;
        active.classList.remove("active");        
    },"1200")
}